.userInfo{
    overflow: visible;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    transition: .2s;
    height: 100%;
}

.actor{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 9px;
}
.userInfo picture{
    border-radius: 50%;
    display: grid;
    /* padding: .7rem; */
    place-items: center;
    /* box-shadow: 0 2px 30px 0 black inset; */
}
.userInfo picture > *{
    grid-column: 1;
    grid-row: 1;
}

.userInfo picture img{
    border-radius: 50%;
    width: 100px;
    filter: grayscale(100%) drop-shadow(0 0 0 transparent);
    transition: .2s ease-in-out;
}
.userInfo picture img.front{
    transition: .4s ease-in-out;
    transform: translate3D(0,0,0) scale(1);
}



.userInfo span{
    color: var(--green);
    display: inline;
}

.userTitles{
    display: flex;
    gap: 5px;
    margin-top: 5px;
}


@media screen and (min-width:768px) {
    

    @keyframes from-left {
        0%   {transform: translateX(-100vw);}
        70%  {transform: translateX(10px);}
        100% {transform: translateX(0);}
    }

   
    .userInfo img,
    .userInfo p,
    .userInfo span,
    .userInfo h1{
        animation-name: from-left;
        animation-duration: 1s;
        animation-fill-mode: backwards;
    }
    
    .userInfo img{animation-delay: .55s;}

    /* .userInfo img.front{animation-delay: .6s;} */
    
    .userInfo p{animation-delay: 0s;}
    .userInfo span{animation-delay: .05s;}
    .userInfo h1{animation-delay: .5s;}

    .userInfo span{
        position: relative;
    }
    .userInfo span::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        border-radius: 2px;
        background-color: var(--green);
        display: block;
        height: 1px;
        transition: .2s ease-in-out;
        width: 0;
        opacity: 0;
    }
    .userInfo:hover  span:after{
        width: 100%;
        opacity: 1;
    }
    .userInfo:hover  .front{
        /* transition: .6s; */
        transform: translate3D(0, -6.5px ,0) scale(1.15);
        border-radius: 0px;
        /* opacity:1; */
        filter: grayscale(0) drop-shadow(-4px 0px 2px rgba(0,0,0, 0.5));
    }
    .userInfo:hover  .back{
        border-radius: 5px;
        /* transition: .6s; */
    }  

    .userInfo picture:hover .front{
        transform: translate3D(0, -6.5px ,0) scale(1.22);
        /* box-shadow: 0 2px 30px 0 black inset; */
    }
    .userInfo picture:hover .back{
        transform: scale(0.95);
    }
}


@media  screen and (max-width: 768px) {
    .actor{
        display: block;
    }
}

@media  screen and (max-width: 460px) {

    .userInfo{
        align-items: center;
        text-align: center;
        gap: 3vw;
    }
    /* .pullArrow{
        display: none;
        animation: none;
    }    */
    .userTitles{
        justify-content: center;
    }
    .userInfo img,
    .userInfo p,
    .userInfo span,
    .userInfo h1{
        animation: none;
    }
}