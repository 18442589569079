#Obstacles{
    position: absolute;
    bottom: -70px;
    width: 100%;
    height: 76px;
    /* border: 1px solid red; */
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.obstacle{
    width: 0;
    height: 100%;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 20px solid var(--darkGreen);
    transform: rotate(180deg);
    animation-name: move;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    right: -30px

}
@keyframes move{
    from{
        right: -30px
    }
    to{
        right: 100%
    }
}