#Level{
    position: absolute;
    bottom: -70px;
    width: 100%;
    height: 76px;
    /* border: 1px solid blue; */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

#background{
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    overflow: hidden;
}

@keyframes animateCloud {
    0% {
        transform: translateX(100vw) scale(0.1);
    }
    100% {
        transform: translateX(-100vw) scale(0.1);
    }
}
.cloud-wrapper {
	animation: animateCloud 35s linear infinite;
	transform: scale(0.1);
}
.cloud {
	background: #fff;
	background: -moz-linear-gradient(top,  #fff 5%, #f1f1f1 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(5%,#fff), color-stop(100%,#f1f1f1));
	background: -webkit-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: -o-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: -ms-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );
	border-radius: 100px;
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	height: 120px;
	position: relative;
    display:inline-block;
	width: 350px;
}

.cloud:after, .cloud:before {
    background: #fff;
	content: '';
	position: absolute;
	z-indeX: -1;
}

.cloud:after {
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;

	height: 100px;
	left: 50px;
	top: -50px;
	width: 100px;
}

.cloud:before {
	-webkit-border-radius: 200px;
	-moz-border-radius: 200px;
	border-radius: 200px;

	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
}
